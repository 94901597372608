import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export enum LoadingState {
  SAVING = 'Saving...',
  SAVED = 'Saved',
  FAILED = 'Failed',
  NA = '',
  SYNCING = 'Syncing...',
}

interface FeedbackLoadingState {
  loadingState: LoadingState;
}

const initialState: FeedbackLoadingState = {
  loadingState: LoadingState.NA,
};

export const feedbackLoadingSlice = createSlice({
  name: 'feedback_loading',
  initialState,
  reducers: {
    setLoadingState: (state, action: PayloadAction<LoadingState>) => {
      state.loadingState = action.payload;
    },
  },
});

export const { setLoadingState } = feedbackLoadingSlice.actions;

export default feedbackLoadingSlice.reducer;
