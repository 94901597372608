import { LinearProgress } from '@mui/material';

import classes from './index.module.scss';
import SessionNotStarted from '../../assets/images/SessionNotStarted.png';
import { useAppSelector } from '../../hooks/useAppSelector';

interface LoadingScreenProps {
  message: string;
}

const LoadingScreen = ({ message }: LoadingScreenProps) => {
  const authState = useAppSelector((state) => state.auth);

  return (
    <section className={classes.container}>
      <div className={classes.logoContainer}>
        {authState.companyLogo.length > 0 && (
          <img
            className={classes.logo}
            style={{
              filter:
                authState.companyName === 'HireHunch'
                  ? 'brightness(0) invert(1)'
                  : 'initial',
            }}
            src={authState.companyLogo}
            alt={authState.companyName || 'HunchVue'}
          />
        )}
      </div>
      <img
        className={classes.loadingImage}
        src={SessionNotStarted}
        alt="Loading"
      />
      <div className={classes.loadingInfo}>
        <span className={classes.loadingText}>{message}</span>
        <LinearProgress variant="indeterminate" color="inherit" />
      </div>
    </section>
  );
};

export default LoadingScreen;
