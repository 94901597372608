import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OtherRoundFeedbackState {
  currentRound: string;
  roundNames: string[];
  data: any[];
}

const initialState: OtherRoundFeedbackState = {
  data: [],
  currentRound: '',
  roundNames: [],
};

export const otherRoundsFeedbackSlice = createSlice({
  name: 'otherRoundsFeedback',
  initialState,
  reducers: {
    setCurrentRound: (state, action: PayloadAction<string>) => {
      state.currentRound = action.payload;
    },
    setData: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
    setRoundNames: (state, action: PayloadAction<string[]>) => {
      state.roundNames = action.payload;
    },
  },
});

export const { setCurrentRound, setData, setRoundNames } =
  otherRoundsFeedbackSlice.actions;

export default otherRoundsFeedbackSlice.reducer;
