import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './Auth/AuthSlice';
import { chatSlice } from './Chat/chatSlice';
import { demoSlice } from './Demo/demoSlice';
import { meetingSlice } from './Meeting/MeetingSlice';
import { meetingSettingsSlice } from './MeetingSettings/meetingSettingsSlice';
import { drawSlice } from './Playback/DrawSlice';
import { playbackSlice } from './Playback/PlaybackSlice';
import { playbackV2Slice } from './Playback/PlaybackV2Slice';
import { playbackAuthSlice } from './PlaybackAuth/PlaybackAuthSlice';
import { recordingSlice } from './Recording/RecordingSlice';
import { feedbackSlice } from './Feedback/FeedbackSlice';
import { interviewerNoteSlice } from './InterviewerNotes/Notes';
import { tagSlice } from './Tags/TagSlice';
import { activePopupSlice } from './ActivePopup/ActivePopupSlice';
import { candidateActivitySlice } from './CandidateActivity/CandidateActivitySlice';
import { tabsStateSlice } from './TabsState';
import { playbackV4Slice } from './Playback/PlaybackV4Slice';
import { customFeedbackSlice } from './Feedback/CustomFeedbackSlice';
import { feedbackLoadingSlice } from './Feedback/FeedbackLoadingSlice';
import { otherRoundsFeedbackSlice } from './Feedback/OtherRoundFeedbackSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    meeting: meetingSlice.reducer,
    meetingSettings: meetingSettingsSlice.reducer,
    playbackAuth: playbackAuthSlice.reducer,
    playback: playbackSlice.reducer,
    playbackV2: playbackV2Slice.reducer,
    playbackV4: playbackV4Slice.reducer,
    draw: drawSlice.reducer,
    chat: chatSlice.reducer,
    recording: recordingSlice.reducer,
    demo: demoSlice.reducer,
    feedback: feedbackSlice.reducer,
    notes: interviewerNoteSlice.reducer,
    tags: tagSlice.reducer,
    activePopup: activePopupSlice.reducer,
    candidateActivity: candidateActivitySlice.reducer,
    tabsState: tabsStateSlice.reducer,
    customFeedback: customFeedbackSlice.reducer,
    feedbackLoading: feedbackLoadingSlice.reducer,
    otherRoundFeedback: otherRoundsFeedbackSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        // ignoredActions: ['your/action/type'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload'],
        // Ignore these paths in the state
        ignoredPaths: ['meetingSettings.meetingConfiguration', 'payload'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
