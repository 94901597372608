import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type feedbackId =
  | 'questionsDiscussed'
  | 'positives'
  | 'negatives'
  | 'overallFeedback';

export enum LoadingState {
  SAVING = 'Saving...',
  SAVED = 'Saved',
  FAILED = 'Failed',
  NA = '',
  SYNCING = 'Syncing...',
}

interface FeedbackState {
  loadingState: LoadingState;
  data: {
    recommendToHire?: number;
    questionsDiscussed?: string;
    internalQuestionsDiscussed: string[];
    positives?: string;
    negatives?: string;
    overallFeedback?: string;
  };
  feedbackFormUrl?: string;
  triggerFetchQuestions: number;
}

const initialState: FeedbackState = {
  loadingState: LoadingState.NA,
  data: {
    recommendToHire: undefined,
    questionsDiscussed: undefined,
    positives: undefined,
    negatives: undefined,
    overallFeedback: undefined,
    internalQuestionsDiscussed: [],
  },
  feedbackFormUrl: '',
  triggerFetchQuestions: 0,
};

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setRecommendToHire: (state, action: PayloadAction<number>) => {
      state.data.recommendToHire = action.payload;
    },
    setFeedbackFormUrl: (state, action: PayloadAction<string>) => {
      state.feedbackFormUrl = action.payload;
    },
    setQuestionsDiscussed: (state, action: PayloadAction<string>) => {
      state.data.questionsDiscussed = action.payload;
    },
    setInternalQuestionsDiscussed: (state, action: PayloadAction<string[]>) => {
      state.data.internalQuestionsDiscussed = action.payload;
    },
    setPositives: (state, action: PayloadAction<string>) => {
      state.data.positives = action.payload;
    },
    setNegatives: (state, action: PayloadAction<string>) => {
      state.data.negatives = action.payload;
    },
    setOverallFeedback: (state, action: PayloadAction<string>) => {
      state.data.overallFeedback = action.payload;
    },
    setLoadingState: (state, action: PayloadAction<LoadingState>) => {
      state.loadingState = action.payload;
    },
    setTriggerFetchQuestions: (state, action: PayloadAction<number>) => {
      state.triggerFetchQuestions = action.payload;
    },
  },
});

export const {
  setRecommendToHire,
  setQuestionsDiscussed,
  setPositives,
  setNegatives,
  setOverallFeedback,
  setInternalQuestionsDiscussed,
  setTriggerFetchQuestions,
  setFeedbackFormUrl,
} = feedbackSlice.actions;

export default feedbackSlice.reducer;
